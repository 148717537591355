import React from "react";
import { useNavigate } from "react-router";

function AboutSettings () {
    const navigate = useNavigate()

    const handleGoBack = ()=> {
        // const defaultTab = document.getElementById('default'); 
        // defaultTab.style.display = 'flex'

        navigate('/settings')        
        
    } 


    return (
        <>
                        <div className="entire-body">
                <div id="head-content" className="head-content">
                    <span className="go-back">
                    </span>
                    <h4>
                            {/* <a href="/settings" onClick={handleGoBack} style={{textDecoration: "none"}}> */}
                            <img onClick={handleGoBack} style={{width: "40px", marginRight: "20px", height: "20px"}} src="/static/images/go-back.svg" alt=""/>
                            {/* </a> */}
                        About Us</h4>
                </div>
                <>
                <div className="profile-top">
                    <h3>Privacy Policy</h3>
                    </div>
                    <div className="profile-top">
                        <h3>Terms and Condition</h3>
                        <div 
                        style={{display: 'flex',
                        justifyContent: 'space-evenly'}}
                        
                        className="personal-info"></div>
                    </div>
                    
                    </> 
            
        
                    
        
            
            </div>                

        </>
    //     <div id="about">
    //     <h4 style={{textAlign: "center", margin: "50% 0"}}>This page is Under Construction!!!
    //             {/* <a href="" onClick={handleGoBack}> */}
    //                 <button style={{border: "none", marginTop: "20px"}}>
    //                     <img style={{width: "20px", height: "15px"}} src="/static/images/go-back.svg" alt=""/>
    //                     GO BACK</button>
    //             {/* </a> */}
        
    //         </h4>
    //    </div>
    
    )
}

export default AboutSettings;