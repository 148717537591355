import React,  { useState, useEffect} from "react";
import { useCookies } from 'react-cookie';



// const BASE_URL = 'http://localhost:8000/api'


// const BASE_URL = 'http://192.168.8.117:8000/api/renderapi'

// const BASE_URL = 'http://192.168.8.115:8000/api/renderapi'
const BASE_URL = 'https://source.nearbyvendor.com/api/renderapi'
// const BASE_URL = 'http://192.168.1.157:8000/api'


export default class APIContents {

    static async Feeds(token){

        let auth = ''
        if (token === undefined){
            auth = ''
        }else {
            auth = `Token ${token}`
        }

        return await fetch(`${BASE_URL}/`, {
            'method': 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            }
        }).then(resp => resp.json())
    }

    static async CreateFeeds(body, token) {

        // const formData = new FormData();

        // formData.append('image', images[0])


        let auth = ''
        if (token === undefined){
            auth = ''
        }else {
            auth = `Token ${token}`
        }

        return fetch(`${BASE_URL}/`, {
            'method': 'POST',
            headers: {
                // 'Content-Type' : 'multipart/form-data',
                'Authorization' : `${auth}`
            },
            body : body,
        })
        .then(resp => resp.json())
    }

    static async DeletePost(body, token) {

        let auth = ''
        if (token === undefined){
            auth = ''
        }else {
            auth = `Token ${token}`
        }

        return await fetch(`${BASE_URL}/`, {
            'method': 'DELETE',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `${auth}`
            },
            body : JSON.stringify(body),
        })
        .then(resp => resp.json())
    }

    static async Login(body){
        return await fetch(`${BASE_URL}/login`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token}`
            },
            body: JSON.stringify(body),

        })
        .then(resp => resp.json())

    }


    static async Profiles(token){

        let auth = ''
        if (token === undefined || token === null){
            auth = ''
        }else {
            auth = `Token ${token}`
        }

        return await fetch(`${BASE_URL}/get_users`, {
            'method': 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            }
        }).then(resp => resp.json())
    }

    static async UserProfile(token){

        // let auth = ''
        // if (token === undefined || token === null){
        //     auth = ''
        // }else {
        //     auth = `Token ${token}`
        // }

        if (token !== undefined){
            return await fetch(`${BASE_URL}/get_user/${token}`, {
                'method': 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `${auth}`
                }
            }).then(resp => resp.json())
    
        }

    }


    static async LikePost(body, token){

        let auth = ''
        if (token === undefined || token === null){
            auth = ''
        }else {
            auth = `Token ${token}`
        }
  

        return await fetch(`${BASE_URL}/like`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
            body: JSON.stringify(body)
        })
        .then(resp=> resp.json())
    }

    static async CheckLikePost(token){

        let auth = ''
        if (token === undefined || token === null){
            auth = ''
        }else {
            auth = `Token ${token}`
        }
  

        return await fetch(`${BASE_URL}/checklike`, {
            'method': 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
        })
        .then(resp=> resp.json())
    }


    static async Chat(body, token){

        let auth = ''
        if (token === undefined || token === null){
            auth = ''
        }else {
            auth = `Token ${token}`
        }
  

        return await fetch(`${BASE_URL}/chats`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
            body: JSON.stringify(body)
        })
        .then(resp=> resp.json())
    }
    static async Comment(body, token){

        let auth = ''
        if (token === undefined || token === null){
            auth = ''
        }else {
            auth = `Token ${token}`
        }
  

        return await fetch(`${BASE_URL}/comment`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
            body: JSON.stringify(body)
        })
        .then(resp=> resp.json())
    }

    static async GetComment(token){

        let auth = ''
        if (token === undefined || token === null){
            auth = ''
        }else {
            auth = `Token ${token}`
        }
  

        return await fetch(`${BASE_URL}/comment`, {
            'method': 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
        })
        .then(resp=> resp.json())
    }


    static async Follow(body, token){

        let auth = ''
        if (token === undefined || token === null){
            auth = ''
        }else {
            auth = `Token ${token}`
        }
  

        return await fetch(`${BASE_URL}/follow`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
            body: JSON.stringify(body)
        })
        .then(resp=> resp.json())
    }

    static async getFollowers(token){

        let auth = ''
        if (token === undefined || token === null){
            auth = ''
        }else {
            auth = `Token ${token}`
        }
  

        return await fetch(`${BASE_URL}/follow`, {
            'method': 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
        })
        .then(resp=> resp.json())
    }

    static async GetCurrentUser(token, username){

        let auth = ''
        if (token === undefined || token === null){
            auth = ''
        }else {
            auth = `Token ${token}`
        }
  

        return await fetch(`${BASE_URL}/fetchcurruserdetails?username=${username}`, {
            'method': 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `${auth}`
            },
        })
        .then(resp=> resp.json())
    }


    static async UpdateData(body, token, contentType) {
        let auth = ''

        if (token === undefined || token === null)
        {
            auth = ''
        }
        else {
            auth = `Token ${token}`
        }


        if (contentType === undefined || contentType === null)
        {
            return await fetch(`${BASE_URL}/updatedata`,
            {
                'method': 'POST',
                headers: {
                    
                    // 'Content-Type': `${ctype}`,
                    // 'Content-Type': 'application/json',
                    'Authorization':    `${auth}`
                },
                body: body
            })
            .then(resp => resp.json())
    
        }
        else {
            return await fetch(`${BASE_URL}/updatedata`,
            {
                'method': 'POST',
                headers: {
                    
                    'Content-Type': `${contentType}`,
                    // 'Content-Type': 'application/json',
                    'Authorization':    `${auth}`
                },
                body: body
            })
            .then(resp => resp.json())
        }
    }

    
    static async ChatLists(token) {
        let auth = ''
        if (token === undefined) {
            auth = ''
        } else {
            auth = `Token ${token}`
        }

        return await fetch(`${BASE_URL}/chats`, {
            'method' : "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            }
        }).then(resp => resp.json())
    }

    static async GetMessages(token, uniquecode) {
        let auth = ''
        if (token === undefined) {
            auth = ''
        } else {
            auth = `Token ${token}`
        }

        return await fetch(`${BASE_URL}/chat/${uniquecode}/messages/`, {
            'method' : "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
        }).then(resp => resp.json())
    }

    static async SendMessage(token, body, uniquecode) {
        let auth = ''
        if (token === undefined) {
            auth = ''
        } else {
            auth = `Token ${token}`
        }

        return await fetch(`${BASE_URL}/chat/${uniquecode}/messages/`, {
            'method' : "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
            body: JSON.stringify(body)
        }).then(resp => resp.json())
    }

    static async SearchUser(token, username) {
        let auth = ''
        if (token === undefined) {
            auth = ''
        } else {
            auth = `Token ${token}`
        }

        return await fetch(`${BASE_URL}/search/${username}/`, {
            'method' : "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
            // body: JSON.stringify(body)
        }).then(resp => resp.json())
    }

    static async getNotifications(token, username) {
        let auth = ''
        if (token === undefined) {
            auth = ''
        } else {
            auth = `Token ${token}`
        }

        return await fetch(`${BASE_URL}/notifications`, {
            'method' : "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${auth}`
            },
            // body: JSON.stringify(body)
        }).then(resp => resp.json())
    }


}


